<script setup type="module">
import { ref, inject, computed } from 'vue';
import { useCertificateStore } from '@/stores/certificate';
import { useUserStore } from '@/stores/user';
import { useOptionsStore } from '@/stores/options';
import { storeToRefs } from 'pinia';
import { router } from '@inertiajs/vue3';
import useDownloadMixin from '@/mixins/axiosDownload';
const { methods: { downloadFromAxiosResponse } } = useDownloadMixin;

const params = ref({
    term: '',
    status: null,
    remittanceStatus: null,
    purchaseDate: null,
    dealership: null,
});

const certificateStore = useCertificateStore();
const { searchCertificates, getCustomerName, getFsmName, printCertificate, clearActiveCertificate } = certificateStore;
const { all } = storeToRefs(certificateStore);
const modals = inject('modals');

const optionsStore = useOptionsStore();
const { statuses, remittanceStatuses, dealerships } = storeToRefs(optionsStore);

const userStore = useUserStore();
const { userProfile } = storeToRefs(userStore);

const getDealerships = computed(() => {
    if (userProfile.value.hasRole(['dealership_manager', 'fsm'])) {
        return dealerships.value.filter(dealership => userProfile.value.dealerships.some(userDealership => userDealership.id === dealership.id));
    }
    return dealerships.value;
});

const clearSearch = () => {
    params.value.term = '';
    search();
};

const tableUpdate = ({page, sortBy, itemsPerPage}) => {
    params.value.page = page;
    params.value.sort_by = sortBy;
    params.value.per_page = itemsPerPage;
    search();
};

const search = () => {
    searchCertificates(params.value);
};

const exportCertificates = async () => {
    const dateRange = await modals.show('MasterExportModal', {
        props: {
            title: 'Confirm',
            prompt: 'Are you sure you want to cancel? Any unsaved changes will be lost.'
        }
    });

    if (dateRange && dateRange.length === 2) {
        window.location.href = `/certificates/export?start_date=${dateRange[0]}&end_date=${dateRange[1]}`;
    }
};

const visitPath = (route) => {
    router.visit(route);
};

const goToCreatePage = () => {
    clearActiveCertificate();
    visitPath('certificates/create');
};

const downloadCertificate = (certificate) => {
    printCertificate(certificate).then(response => {
        downloadFromAxiosResponse(response, `certificate_${certificate.deal_number}.pdf`);
    });
};

</script>
<template>
<MainContentLayout>
    <template #breadcrumb>
        <BreadCrumb />
    </template>
    <template #title>
        {{ $t('label.certificates') }}
    </template>
    <template #actions>
        <StyledButton @click="goToCreatePage">
            <FontAwesomeIcon
                icon="plus"
                class="mr-1" />
            {{ $t('label.new_certificate') }}
        </StyledButton>
        <StyledButton v-if="userProfile.hasRole(['super_admin', 'admin', 'dealership_manager'])" class="ml-2" @click="exportCertificates">
            <FontAwesomeIcon
                icon="download"
                class="mr-1" />
            {{ $t('label.download_master_export') }}
        </StyledButton>
    </template>
    <div class="grid md:grid-cols-5 md:gap-4 md:mb-4">
        <StyledSearch
            v-model="params.term"
            v-debounce:500ms="search"
            name="search"
            :label="$t('label.search')"
            :debounce-events="['input']"
            @click:clear="clearSearch" />
        <StyledDropdown
            v-model="params.status"
            name="status"
            item-title="name"
            item-value="id"
            :options="statuses"
            :label="$t('label.status')"
            @input="search" />
        <StyledDropdown
            v-model="params.remittanceStatus"
            name="remittance_status"
            item-title="name"
            item-value="id"
            :options="remittanceStatuses"
            :label="$t('label.remittance_status')"
            @input="search" />
        <StyledDatepicker
            v-model="params.purchaseDate"
            :label="$t('label.purchase_date')"
            view-mode="year"
            :range="true"
            name="purchase_date_range"
            @input="search" />
        <StyledDropdown
            v-model="params.dealership"
            name="dealership"
            item-title="name"
            item-value="id"
            :options="getDealerships"
            :label="$t('label.dealership')"
            @input="search" />
    </div>

    <DataTable
        v-if="all"
        :data="all"
        :headers="[
            { title: $t('label.cert_no'), key: 'certificate_number' },
            { title: $t('label.deal_no'), key: 'deal_number' },
            { title: $t('label.customer_name'), key: 'customer_name', value: item => getCustomerName(item)  },
            { title: $t('label.remittance_status'), key: 'remittance_status_id', value: item => '-' }, // TODO: remittance status (issue #21)
            { title: $t('label.status'), key: 'global_status_id', value: item => item.global_status.name },
            { title: $t('label.dealership'), key: 'dealership_id', value: item => item.dealership.name },
            { title: $t('label.fsm'), key: 'fsm', value: item => getFsmName(item) },
            { title: $t('label.purchase_date'), key: 'purchase_date' },
        ]"
        :actions="[
            { title: $t('label.view_certificate'), icon: 'file', action: item => visitPath(`certificates/${item.hash}`) },
            { title: $t('label.print_certificate'), icon: 'print', action: item => downloadCertificate(item), isVisible: item => ['completed', 'cancelled', 'refund_requested', 'refunded'].includes(item.global_status?.slug) }
        ]"
        @table-update="tableUpdate" />

</MainContentLayout>
</template>

