import { ref, reactive } from 'vue';
import { defineStore } from 'pinia';
import axios from 'axios';

export const useCertificateStore = defineStore('certificate', () => {
    const all = ref(null);
    const allCerts = ref(null);
    const active = reactive(ref({}));
    const locale = ref('en');
    const isDealModified = reactive(ref(false));
    const isBuyerModified = reactive(ref(false));
    const isVehicleModified = reactive(ref(false));

    const actions = {
        getCustomerName(certificate) {
            return certificate.buyers?.length ? `${certificate.buyers?.[0]?.last_name ?? ''}, ${certificate.buyers?.[0]?.first_name ?? ''}` : '-';
        },
        getFsmName: (certificate) => certificate.user ? `${certificate.user?.last_name}, ${certificate.user?.first_name}` : '-',
        searchCertificates: (params) => axios.get('/api/v1/certificates', { params }),
        storeCertificate: (params) => axios.post('/api/v1/certificates', params),
        updateCertificate: (params) => axios.patch('/api/v1/certificates/' + params.hash, params),
        completeCertificate: (params) => axios.post('/api/v1/certificates/complete/' + params.hash, params),
        destroyCertificate: (params) => axios.delete('/api/v1/certificates/' + params.hash),
        updateCertificateStatus: (params) => axios.patch('/api/v1/certificates/status/' + params.hash, params),
        voidCertificate: (params) => axios.patch('/api/v1/certificates/void/' + params.hash, params),
        printCertificate: (params) => axios.get(`/api/v1/certificates/${params.hash}/print`, {
            responseType: 'blob'
        }),
        applyValueBundle: (params) => axios.post('/api/v1/certificates/apply-value-bundle/' + params.hash),
        updateValueBundleCharges: (params) => axios.patch('/api/v1/certificates/value-bundle/' + params.hash, params),
        deleteValueBundle: (params) => axios.delete('/api/v1/certificates/delete-value-bundle/' + params.hash),
        setDealModified(value) {
            isDealModified.value = value;
        },
        requestRefund: (params) => axios.post('/api/v1/certificates/request-refund/' + params.hash),
        // Buyers store and update
        saveBuyer: (params) => axios.patch('/api/v1/certificates/buyer/' + params.hash, params),
        setBuyerModified(value) {
            isBuyerModified.value = value;
        },
        // Vehicle store and update
        saveVehicle: (params) => axios.patch('/api/v1/certificates/vehicle/' + params.hash, params),
        decodeVin: (params) => axios.post('/api/v1/certificates/vehicle/vin', params),
        saveProduct: (params) => axios.post(`/api/v1/certificates/${params.hash}/product/`, params),
        setVehicleModified(value) {
            isVehicleModified.value = value;
        },
        clearActiveCertificate: () => {
            active.value = null;
        }
    };

    return {
        all,
        allCerts,
        active,
        isDealModified,
        isBuyerModified,
        isVehicleModified,
        locale,
        ...actions,
    };
});
