<script setup>
import { ref, watch, defineExpose, inject, computed } from 'vue';
import { router } from '@inertiajs/vue3';
import { handleError } from '@/mixins/ErrorHandlingMixin';
import { useOptionsStore } from '@/stores/options';
import { useUserStore } from '@/stores/user';
import { useCertificateStore } from '@/stores/certificate';
import { storeToRefs } from 'pinia';
import StyledVDatepicker from '@/components/StyledVDatepicker';

const props = defineProps({
    readOnly: {
        type: Boolean,
        default: false
    }
});

const optionsStore = useOptionsStore();
const { dealerships, provinces, lenders, dealTypes } = storeToRefs(optionsStore);

const userStore = useUserStore();
const { userProfile } = storeToRefs(userStore);
const getDealerships = computed(() => {
    if (userProfile.value.hasRole(['dealership_manager', 'fsm'])) {
        return dealerships.value.filter(dealership => userProfile.value.dealerships?.some(userDealership => userDealership.id === dealership.id));
    }
    return dealerships.value;
});

const { active } = storeToRefs(useCertificateStore());
const certificateStore = useCertificateStore();
const { storeCertificate, updateCertificate, setDealModified } = certificateStore;
const modals = inject('modals');
const emit = defineEmits(['onModify']);

const form = ref({
    mode: 'Create',
    deal_number: null,
    dealership_id: null,
    dealership_gst_number: null,
    purchase_date: new Date(),
    deal_type: null,
    province_id: null,
    lender_id: null,
    lender_phone: null,
});

const onInputDealInformation = () => {
    if (form.value.mode !== 'Create') {
        emit('onModify');
    }
};

const save = async () => {
    try {
        const action = form.value.mode === 'Create' ? storeCertificate : updateCertificate;
        const response = await action({
            ...form.value,
            hash: form.value.hash,
        });
        if (response.data.certificate) {
            router.visit('/certificates/' + response.data.certificate);
        }
    } catch (error) {
        handleError(error);
    }
};

const getDealershipGstNumberAndProvince = _ => {
    const dealership = dealerships.value.find(dealer => dealer.id === form.value.dealership_id);
    if (dealership) {
        form.value.dealership_gst_number = dealership.dealership_gst_number;
        form.value.province_id = dealership.province_id;
    }
};

const getLenderPhoneNumber = _ => {
    const lender = lenders.value.find(lender => lender.id === form.value.lender_id);

    if (lender) {
        form.value.lender_phone = lender.contact_number;
    }
};

const checkDealType = () => {
    if (form.value.deal_type === 'cash') {
        form.value.lender_id = null;
        form.value.lender_phone = null;
    }

    onInputDealInformation();
};

const setActiveCertificate = (certificate) => {
    form.value = {
        ...certificate
    };
    form.value.purchase_date = new Date(`${form.value.purchase_date}T00:00:00.000`);
};

const normalizeFormData = (data) => {
    return {
        deal_number: data?.deal_number ?? '',
        dealership_id: data?.dealership_id ?? '',
        dealership_gst_number: data?.dealership_gst_number ?? '',
        deal_type: data?.deal_type ?? '',
        province_id: data?.province_id ?? '',
        lender_id: data?.lender_id ?? '',
        lender_phone: data?.lender_phone ?? ''
    };
};

const changingDealership = async () => {
    if (!active.value?.province_id) {
        getDealershipGstNumberAndProvince();
        return;
    }

    const confirm = await modals.show('ConfirmationModal', {
        props: {
            title: 'Are you sure?',
            prompt: 'Changing the dealership on the certificate will update the Deal Tax Province to make the new dealership.'
        }
    })
        .catch(() => false);

    if (confirm) {
        getDealershipGstNumberAndProvince();
    } else {
        form.value.dealership_id = active.value.dealership_id;
    }
};

watch (() => active.value, () => {
    setActiveCertificate(active.value);
});

watch(() => form.value, (newVal) => {
    const normalizedNewVal = normalizeFormData(newVal);
    const normalizedActiveVal = normalizeFormData(active.value);
    const checkVehicle = JSON.stringify(normalizedNewVal) !== JSON.stringify(normalizedActiveVal);
    setDealModified(checkVehicle);
}, { deep: true });

defineExpose({
    form,
    save
});
</script>

<template>
<div class="border rounded p-3 shadow-lg">
    <h4 class="h6 pl-1 mb-1">{{ $t('label.dealer_information') }}</h4>
    <hr class="mb-4">
    <div class="grid grid-cols-2 gap-3">
        <StyledDropdown
            v-model="form.dealership_id"
            name="dealership_id"
            item-title="name"
            item-value="id"
            :options="getDealerships"
            :label="$t('label.dealership')"
            :disabled="props.readOnly"
            @input="changingDealership" />
        <StyledInput
            v-model="form.dealership_gst_number"
            :label="$t('label.dealership_gst_number')"
            name="dealership_gst_number"
            :placeholder="$t('label.dealership_gst_number')"
            disabled />
    </div>

    <h4 class="h6 pl-1 mb-2">{{ $t('label.deal_information') }}</h4>
    <div class="grid grid-cols-2 gap-3">
        <StyledInput
            v-model="form.deal_number"
            :label="$t('label.deal_number')"
            name="deal_number"
            :placeholder="$t('label.deal_number')"
            :disabled="props.readOnly || !!active?.id" />
        <StyledVDatepicker
            v-model="form.purchase_date"
            :label="$t('label.purchase_date')"
            name="purchase_date"
            :disabled="props.readOnly" />
        <StyledDropdown
            v-model="form.deal_type"
            name="deal_type"
            item-title="name"
            item-value="value"
            :options="dealTypes"
            :label="$t('label.deal_type')"
            :disabled="props.readOnly"
            @input="checkDealType" />
        <StyledDropdown
            v-model="form.province_id"
            item-title="name"
            item-value="id"
            :options="provinces"
            name="province_id"
            :placeholder="$t('label.please_select')"
            :label="$t('label.deal_tax_province')"
            :disabled="props.readOnly"
            @input="onInputDealInformation" />
        <StyledDropdown
            v-if="form.deal_type !== 'cash'"
            v-model="form.lender_id"
            item-title="name"
            item-value="id"
            :options="lenders"
            name="lender_id"
            :placeholder="$t('label.please_select')"
            :label="$t('label.lender')"
            :disabled="props.readOnly"
            @input="getLenderPhoneNumber" />
        <StyledMask
            v-if="form.deal_type !== 'cash'"
            v-model="form.lender_phone"
            :mask="['###-###-####']"
            :label="$t('label.lender_phone')"
            name="lender_phone"
            placeholder="###-###-####"
            :disabled="props.readOnly" />
    </div>
</div>
</template>
