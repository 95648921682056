<script setup type="module">
import { ref, watch, computed, inject } from 'vue';
import { useCertificateStore } from '@/stores/certificate';
import { useOptionsStore } from '@/stores/options';
import { storeToRefs } from 'pinia';
import BuyerInformation from '@/components/Certificate/BuyerInformation';
import DealInformation from '@/components/Certificate/DealInformation';
import VehicleInformation from '@/components/Certificate/VehicleInformation';
import ProductInformation from '@/components/Certificate/ProductInformation';
import CertificateTotals from '@/components/Certificate/CertificateTotals';
import SlideOver from '@/components/SlideOver';
import useDownloadMixin from '@/mixins/axiosDownload';
import { handleError } from '@/mixins/ErrorHandlingMixin';
import { useErrorStore } from '@/stores/error';
const { methods: { downloadFromAxiosResponse } } = useDownloadMixin;

const errorStore = useErrorStore();
const { active, isDealModified, isBuyerModified, isVehicleModified } = storeToRefs(useCertificateStore());
const certificateStore = useCertificateStore();
const {
    updateCertificateStatus,
    printCertificate,
    applyValueBundle,
    deleteValueBundle,
    completeCertificate,
    setDealModified,
    setBuyerModified,
    setVehicleModified,
    requestRefund,
    updateCertificate
} = certificateStore;
const { products } = storeToRefs(useOptionsStore());
const props = defineProps({
    mode: {
        type: String,
        default: 'Create'
    },
});
const modals = inject('modals');
const tab = ref('deal-information');

const form = ref({
    readOnly: false,
});
const dealComponentRef = ref(null);
const buyerComponentRef = ref(null);
const vehicleComponentRef = ref(null);
const productComponentRefs = ref([]);
const hasBuyerErrors = computed(() => {
    return getErrorCount('buyer');
});

const hasDealErrors = computed(() => {
    const errorKeys = ['deal_number', 'deal_type', 'dealership_gst_number', 'dealership_id', 'province_id'];
    return errorKeys.reduce((count, key) => {
        return count + (errorStore?.all?.[key]?.length || 0);
    }, 0);
});

const hasVehicleErrors = computed(() => {
    return getErrorCount('vehicle');
});

const hideRequestRefundButton = computed(() => {
    return !['refund_requested', 'refunded'].includes(active.value?.global_status?.slug);
});

const isCompletedOrCancelled = computed(() => {
    return ['completed', 'cancelled'].includes(active.value?.global_status?.slug);
});

const getErrorCount = (section) => {
    if (!errorStore?.all) {
        return 0;
    }
    const buyerErrorKeys = Object.keys(errorStore.all).filter(key => key.includes(section));
    return buyerErrorKeys.reduce((count, key) => count + (errorStore.all[key]?.length || 0), 0);
};

const ineligibleProducts = computed(() => {
    let ineligibleProducts = {};
    if (active?.value?.province?.abbreviation === 'BC') {
        ineligibleProducts['Antitheft'] = 'Antitheft is not available in British Columbia';
        ineligibleProducts['Autoguard'] = 'Autoguard is not available in British Columbia';
        ineligibleProducts['Tire and Rim'] = 'Tire and Rim is not available in British Columbia';
    }

    const vehicle = active?.value?.vehicle;
    if (vehicle) {
        const vehicleYear = vehicle.year ?? 0;
        const currentYear = new Date().getFullYear();
        const vehicleAge = currentYear - vehicleYear;

        if (vehicleAge > 6) {
            ineligibleProducts['Autoguard'] = 'Autoguard is not available for vehicles older than 6 years';
        }

        if (vehicleAge >= 10) {
            ineligibleProducts['Key and Remotes'] = 'Key and Remotes is not available for vehicles older than 10 years';
        }

        if (vehicleAge > 6) {
            ineligibleProducts['Protection'] = 'Protection is not available for vehicles older than 6 years';
        }
    }

    return ineligibleProducts;
});

const allSections = computed(() => {
    const sections = [dealComponentRef.value, buyerComponentRef.value, vehicleComponentRef.value, ...productComponentRefs.value];
    const form = [];
    for (const section of sections) {
        if (section) {
            form.push(section.form);
        }
    }

    return form;
});

const save = async () => {
    if (!active.value?.id) {
        dealComponentRef.value.save();
        return;
    }

    updateCertificate({
        form: allSections.value,
        hash: active.value.hash
    });
};

const breadCrumbs = ref([]);

const updateStatus = (slug) => {
    const payload = {
        status: slug,
        hash: active.value.hash
    };
    updateCertificateStatus(payload);
};

const validateAndCompleteCertificate = async () => {
    try {
        const payload = {
            ...dealComponentRef?.value?.form ?? active.value,
            buyers: buyerComponentRef?.value?.form?.buyers ?? active.value.buyers,
            vehicle: vehicleComponentRef?.value?.form?.vehicle ?? active.value.vehicle,
            is_submit: true,
            hash: active.value.hash
        };
        const response = await completeCertificate(payload);
        if (response) {
            downloadCertificate(active.value);
        }
    } catch (error) {
        handleError(error);
    }
};

const saveBuyerInformation = () => {
    if (buyerComponentRef.value) {
        buyerComponentRef.value.save();
    }
};

const saveDealInformation = () => {
    if (dealComponentRef.value) {
        dealComponentRef.value.save();
    }
};
const saveVehicleInformation = () => {
    if (vehicleComponentRef.value) {
        vehicleComponentRef.value.save();
    }
};

const applyValueBundleToCertificate = (certificate) => {
    const payload = {
        hash: certificate.hash
    };
    applyValueBundle(payload);
};

const deleteValueBundleFromCertificate = (certificate) => {
    const payload = {
        hash: certificate.hash
    };
    deleteValueBundle(payload);
};

const voidCertificate = () => {
    modals.show('VoidCertificateModal');
};

const editValueBundleCharges = () => {
    modals.show('ValueBundleChargesModal', {
        props: {
            valueBundle: active.value.certificate_bundle
        }
    });
};

const downloadCertificate = (certificate) => {
    printCertificate(certificate).then(response => {
        downloadFromAxiosResponse(response, `certificate_${certificate.deal_number}.pdf`);
    });
};

const requestRefundForCertificate = (certificate) => {
    const payload = {
        hash: certificate.hash
    };
    requestRefund(payload);
};

watch (() => active.value, () => {
    form.value = {
        readOnly: ['completed', 'cancelled'].includes(active.value?.global_status?.slug) || active.value.hasRemittance
    };

    breadCrumbs.value = [
        {
            title: 'Certificates',
            disabled: false,
            href: '/certificates'
        },
        {
            title: active.value?.certificate_number ?? active.value?.hash,
            disabled: true
        }
    ];
});

watch (tab, (newTab, oldTab) => {
    if (oldTab === 'deal-information' && !hasDealErrors.value && isDealModified.value) {
        saveDealInformation();
        setDealModified(false);
    }

    if (oldTab === 'buyer-information' && !hasBuyerErrors.value && isBuyerModified.value) {
        saveBuyerInformation();
        setBuyerModified(false);
    }

    if (oldTab === 'vehicle-information' && !hasVehicleErrors.value && isVehicleModified.value) {
        saveVehicleInformation();
        setVehicleModified(false);
    }
});

</script>
<template>
<div>
    <MainContentLayout :is-for-certificate-page="true">
        <template #breadcrumb>
            <BreadCrumb :custom-crumbs="breadCrumbs" />
        </template>
        <template #title>
            {{ $t(`label.${active ? 'edit' : 'new'}_certificate`) }}
        </template>
        <template #actions>
            <div v-if="props.mode === 'Edit'" class="flex">
                <div v-if="active && active.bundle" class="bg-green-200 rounded-md py-[0.4rem] px-3 mr-4 flex shadow">
                    <p class="text-green-900">Value bundle is applied.</p>
                    <p>
                        <button
                            class="underline font-medium text-green-900 hover:text-green-600"
                            @click="editValueBundleCharges">
                            <FontAwesomeIcon
                                icon="pencil"
                                class="ml-3" />
                            Edit Charges
                        </button>
                    </p>
                </div>
                <div>
                    <v-btn color="primary">
                        Actions
                        <FontAwesomeIcon
                            icon="caret-down"
                            class="ml-3" />
                        <v-menu activator="parent">
                            <v-list>
                                <v-list-item
                                    v-if="form.readOnly"
                                    @click="downloadCertificate(active)">
                                    <v-list-item-title>
                                        <FontAwesomeIcon
                                            icon="file-xmark"
                                            class="mr-1" />
                                        {{ $t('label.generate_certificate') }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    v-if="active.hasRemittance && hideRequestRefundButton"
                                    @click="requestRefundForCertificate(active)">
                                    <v-list-item-title>
                                        <FontAwesomeIcon
                                            icon="file-invoice-dollar"
                                            class="mr-1" />
                                        {{ $t('label.request_refund') }}
                                    </v-list-item-title>
                                </v-list-item>

                                <template v-if="!form.readOnly">
                                    <v-list-item
                                        v-if="active.buyers.length && active.vehicle"
                                        @click="validateAndCompleteCertificate()">
                                        <v-list-item-title>
                                            <FontAwesomeIcon
                                                icon="file-check"
                                                class="mr-1" />
                                            {{ $t('label.complete_certificate') }}
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        v-else
                                        v-tooltip.left="$t('label.certificate_must_be_completed')">
                                        <v-list-item-title>
                                            <FontAwesomeIcon
                                                icon="file-check"
                                                class="mr-1" />
                                            {{ $t('label.complete_certificate') }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </template>
                                <v-list-item
                                    v-if="isCompletedOrCancelled && !active.hasRemittance"
                                    @click="voidCertificate">
                                    <v-list-item-title>
                                        <FontAwesomeIcon
                                            icon="file-xmark"
                                            class="mr-1" />
                                        {{ $t('label.void_certificate') }}
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item
                                    v-if="!form.readOnly"
                                    @click="updateStatus('cancelled')">
                                    <v-list-item-title>
                                        <FontAwesomeIcon
                                            icon="file-xmark"
                                            class="mr-1" />
                                        {{ $t('label.cancel_certificate') }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    v-if="isCompletedOrCancelled && !active.hasRemittance"
                                    @click="updateStatus('in_progress')">
                                    <v-list-item-title>
                                        <FontAwesomeIcon
                                            icon="file-xmark"
                                            class="mr-1" />
                                        {{ $t('label.reopen_certificate') }}
                                    </v-list-item-title>
                                </v-list-item>

                                <template v-if="props.mode === 'Edit' && !active.hasRemittance">
                                    <v-list-item
                                        v-if="active && active.bundle"
                                        :disabled="form.readOnly"
                                        @click="deleteValueBundleFromCertificate(active)">
                                        <v-list-item-title>
                                            <FontAwesomeIcon
                                                icon="check-circle"
                                                class="mr-1" />
                                            {{ $t('label.delete_value_bundle') }}
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        v-else
                                        :disabled="form.readOnly"
                                        @click="applyValueBundleToCertificate(active)">
                                        <v-list-item-title>
                                            <FontAwesomeIcon
                                                icon="check-circle"
                                                class="mr-1" />
                                            {{ $t('label.apply_value_bundle') }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-menu>
                    </v-btn>
                </div>
            </div>
        </template>
        <div class="space-y-3 certificate-details overflow-auto py-3 px-3 shadow">
            <DealInformation ref="dealComponentRef" :read-only="form.readOnly" @onModify="save" />
            <template v-if="active?.id">
                <BuyerInformation ref="buyerComponentRef" :read-only="form.readOnly" @onModify="save" />
                <VehicleInformation ref="vehicleComponentRef" :read-only="form.readOnly" @onModify="save" />
                <template v-if="active?.certificate_products">
                    <div v-for="product in products" :key="product.id">
                        <ProductInformation
                            ref="productComponentRefs"
                            :product="product"
                            :read-only="form.readOnly"
                            :ineligible-reason="ineligibleProducts[product.name]"
                            :all-sections="allSections" />
                    </div>
                </template>
            </template>
            <div class="flex justify-end">
                <StyledButton :label="$t('label.save')" @click="save">{{ $t('label.save') }}</StyledButton>
            </div>
        </div>
    </MainContentLayout>
    <SlideOver v-if="props.mode === 'Edit'">
        <CertificateTotals />
    </SlideOver>
</div>
</template>

