<script setup>
import { ref, defineExpose, watch } from 'vue';
import { useOptionsStore } from '@/stores/options';
import { useCertificateStore } from '@/stores/certificate';
import { storeToRefs } from 'pinia';
import { handleError } from '@/mixins/ErrorHandlingMixin';
import { normalize } from '@/utils/vueUtils';

const props = defineProps({
    readOnly: {
        type: Boolean,
        default: false
    }
});

const optionsStore = useOptionsStore();
const { provinces, genders, titles } = storeToRefs(optionsStore);

const { active} = storeToRefs(useCertificateStore());
const certificateStore = useCertificateStore();
const { saveBuyer, setBuyerModified } = certificateStore;

const form = ref({
    hash: null,
    buyers: [
        {
            id: null,
            title: null,
            first_name: null,
            last_name: null,
            email: null,
            phone: null,
            address: null,
            address2: null,
            city: null,
            province_id: null,
            postal: null,
            gender_id: null,
            treaty_number: null,
            company_name: null,
            is_cobuyer: false,
            is_address_same: false,
        }
    ],
    is_cobuyer_added: false,
});
// Check if active has buyers and update form.buyers if needed
if (active.value?.buyers?.length > 0) {
    form.value.buyers = active.value.buyers.map(buyer => ({ ...buyer }));
    form.value.is_cobuyer_added = form.value.buyers.length > 1;
}

const addCoBuyer = () => {
    if (!form.value.is_cobuyer_added) {
        form.value.buyers.pop();
    } else {
        form.value.buyers.push({
            id: null,
            title: null,
            first_name: null,
            last_name: null,
            email: null,
            phone: null,
            address: null,
            address2: null,
            city: null,
            province_id: null,
            postal: null,
            is_cobuyer: true,
            is_address_same: false,
        });
    }
};

const useBuyerAddress = () => {
    const [buyer, coBuyer] = form.value.buyers;
    if (coBuyer?.is_address_same) {
        const { address, address2, city, province_id, postal } = buyer;
        Object.assign(coBuyer, { address, address2, city, province_id, postal });
    }
};

const save = async () => {
    try {
        await saveBuyer({
            ...form.value,
            hash: active.value.hash,
        });
    } catch (error) {
        handleError(error);
    }
};

const setActiveBuyers = (certificate) => {
    if (certificate?.buyers?.length) {
        form.value.buyers = certificate.buyers.map(buyer => ({ ...buyer }));
    }
};

watch (() => active.value, () => {
    setActiveBuyers(active.value);
});

watch(() => form.value.buyers, (newVal) => {
    const normalizedNewVal = normalize(newVal);
    const normalizedActiveVal = normalize(active.value.buyers);
    const checkBuyers = JSON.stringify(normalizedNewVal) !== JSON.stringify(normalizedActiveVal);
    setBuyerModified(checkBuyers);
}, { deep: true });

defineExpose({
    form,
    save
});
</script>

<template>
<div class="border rounded p-3 shadow-lg">
    <h4 class="h6 pl-1 mb-1">{{ $t('label.buyer_information') }}</h4>
    <hr class="mb-2">
    <StyledRoundSwitch
        v-model="form.is_cobuyer_added"
        :placeholder="$t('label.add_cobuyer')"
        :label="$t('label.add_cobuyer')"
        color="primary"
        inset
        name="is_cobuyer_added"
        :disabled="props.readOnly"
        @input="addCoBuyer" />
    <div v-for="(buyer, index) in form.buyers" :key="index" class="-mt-3">
        <div class="font-bold text-lg pl-1 mb-2">{{ !buyer.is_cobuyer ? $t('label.buyer') : $t('label.cobuyer') }}</div>
        <StyledRoundSwitch
            v-if="buyer.is_cobuyer"
            v-model="buyer.is_address_same"
            :label="$t('label.same_address_as_buyer')"
            color="primary"
            inset
            name="is_address_same"
            :disabled="props.readOnly"
            @input="useBuyerAddress" />
        <form class="grid grid-cols-4 gap-2">
            <StyledDropdown
                v-model="buyer.title"
                name="title"
                item-title="name"
                item-value="name"
                :options="titles"
                :label="$t('label.title')"
                :disabled="props.readOnly" />
            <StyledInput
                v-model="buyer.first_name"
                :label="$t('label.first_name')"
                :name="`buyers.${index}.first_name`"
                :placeholder="$t('label.first_name')"
                :disabled="props.readOnly" />
            <StyledInput
                v-model="buyer.last_name"
                :label="$t('label.last_name')"
                :name="`buyers.${index}.last_name`"
                :placeholder="$t('label.last_name')"
                :disabled="props.readOnly" />
            <StyledInput
                v-model="buyer.email"
                :label="$t('label.email')"
                :name="`buyers.${index}.email`"
                :placeholder="$t('label.email')"
                type="email"
                :disabled="props.readOnly" />
            <StyledMask
                v-model="buyer.phone"
                :mask="['###-###-####']"
                :label="$t('label.phone')"
                :name="`buyers.${index}.phone`"
                placeholder="###-###-####"
                :disabled="props.readOnly" />
            <StyledInput
                v-model="buyer.address"
                :name="`buyers.${index}.address`"
                :placeholder="$t('label.address')"
                :label="$t('label.address')"
                :disabled="buyer.is_address_same || props.readOnly" />
            <StyledInput
                v-model="buyer.address2"
                name="address2"
                :placeholder="$t('label.address_field_2')"
                :label="$t('label.address_field_2')"
                :disabled="buyer.is_address_same || props.readOnly" />
            <StyledInput
                v-model="buyer.city"
                :name="`buyers.${index}.city`"
                :placeholder="$t('label.city')"
                :label="$t('label.city')"
                :disabled="buyer.is_address_same || props.readOnly" />
            <StyledDropdown
                v-model="buyer.province_id"
                item-title="name"
                item-value="id"
                :options="provinces"
                :name="`buyers.${index}.province_id`"
                :placeholder="$t('label.please_select')"
                :label="$t('label.province')"
                :disabled="buyer.is_address_same || props.readOnly" />
            <StyledMask
                v-model="buyer.postal"
                :name="`buyers.${index}.postal`"
                placeholder="A1A 1A1"
                :options="{
                    mask: '@#@ #@#',
                    tokens: {
                        '#': { pattern: /\d/ },
                        '@': { pattern: /[a-zA-Z]/, transform: v => v.toUpperCase()}
                    }
                }"
                :label="$t('label.postal')"
                :disabled="buyer.is_address_same || props.readOnly" />
            <StyledDropdown
                v-model="buyer.gender_id"
                name="gender_id"
                item-title="name"
                item-value="id"
                :options="genders"
                :label="$t('label.gender')"
                :disabled="props.readOnly" />
            <StyledInput
                v-model="buyer.treaty_number"
                :label="$t('label.treaty_number')"
                name="treaty_number"
                :placeholder="$t('label.treaty_number')"
                :disabled="props.readOnly" />
            <StyledInput
                v-model="buyer.company_name"
                :label="$t('label.company_name')"
                name="company_name"
                :placeholder="$t('label.company_name')"
                :disabled="props.readOnly" />
        </form>
    </div>
</div>
</template>
