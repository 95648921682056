<template>
<div class="bg-white rounded-sm" :class="isForCertificatePage ? '' : 'px-4 pb-4'">
    <div class="justify-between items-center flex w-full px-4" :class="isForCertificatePage ? 'shadow' : ''">
        <div class="flex flex-col pt-4">
            <h1 class="h6 pl-1">
                <slot name="title" />
            </h1>
            <slot name="breadcrumb" />
        </div>

        <div class="">
            <slot name="actions" />
        </div>
    </div>
    <slot />
</div>
</template>

<script>
export default {
    inheritAttrs: false,

    props: {
        isForCertificatePage: {
            type: Boolean,
            default: false
        }
    }
};
</script>
